/* tslint:disable */
/* eslint-disable */
/**
 * Stadia Maps Geospatial APIs
 * The Stadia Maps Geospatial APIs provide you with the data you need to build awesome applications.
 *
 * The version of the OpenAPI document: 5.0.5
 * Contact: support@stadiamaps.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PeliasGeoJSONPropertiesAddendumOsm
 */
export interface PeliasGeoJSONPropertiesAddendumOsm {
    [key: string]: any | any;
    /**
     * 
     * @type {string}
     * @memberof PeliasGeoJSONPropertiesAddendumOsm
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof PeliasGeoJSONPropertiesAddendumOsm
     */
    wikipedia?: string;
    /**
     * 
     * @type {string}
     * @memberof PeliasGeoJSONPropertiesAddendumOsm
     */
    wikidata?: string;
    /**
     * 
     * @type {string}
     * @memberof PeliasGeoJSONPropertiesAddendumOsm
     */
    phone?: string;
}

/**
 * Check if a given object implements the PeliasGeoJSONPropertiesAddendumOsm interface.
 */
export function instanceOfPeliasGeoJSONPropertiesAddendumOsm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PeliasGeoJSONPropertiesAddendumOsmFromJSON(json: any): PeliasGeoJSONPropertiesAddendumOsm {
    return PeliasGeoJSONPropertiesAddendumOsmFromJSONTyped(json, false);
}

export function PeliasGeoJSONPropertiesAddendumOsmFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeliasGeoJSONPropertiesAddendumOsm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'website': !exists(json, 'website') ? undefined : json['website'],
        'wikipedia': !exists(json, 'wikipedia') ? undefined : json['wikipedia'],
        'wikidata': !exists(json, 'wikidata') ? undefined : json['wikidata'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function PeliasGeoJSONPropertiesAddendumOsmToJSON(value?: PeliasGeoJSONPropertiesAddendumOsm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'website': value.website,
        'wikipedia': value.wikipedia,
        'wikidata': value.wikidata,
        'phone': value.phone,
    };
}

